var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "edit-btn-map-icon",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.data.click.apply(null, arguments)
        },
      },
    },
    [_c("img", { attrs: { src: require("@/assets/img/map.png"), alt: "" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }